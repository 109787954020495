import 'lightbox.js-react/dist/index.css';
import { initLightboxJS, SlideshowLightbox } from 'lightbox.js-react';
import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/header/Header';
import useFetch from '../../hooks/useFetch';
import styles from './styles.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation'; 
import 'swiper/css/autoplay';
import gsap from 'gsap';
import Loading from '../../components/UI/loading/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Collection() {
  const { title } = useParams();
  const { loading, error, data } = useFetch(`https://julie-demina-portfolio-5cb04ae2d960.herokuapp.com/api/collections?filters[Title][$eq]=${title}&populate[gallery_images][populate][Image]=*`);
  const [images, setImages] = useState([]);
  const [collections, setCollections] = useState([]);
  const swiperRef = useRef(null); 
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Initialize Lightbox.js
  useEffect(() => {
    initLightboxJS("238D-FC51-0E8F-3343", "individual");
  });

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(0);
    }
  }, [title]);

  useEffect(() => {
    if (data && data.data && data.data[0]?.attributes?.gallery_images?.data) {
      const imagesData = data.data[0].attributes.gallery_images.data[0]?.attributes.Image.data;
      if (imagesData) {
        setImages(imagesData);
      }
    }
  }, [data, title]);

  useEffect(() => {
    fetch('https://julie-demina-portfolio-5cb04ae2d960.herokuapp.com/api/collections?populate=*')
      .then(response => response.json())
      .then(data => setCollections(data.data));
  }, []);

  useEffect(() => {
    if (document.querySelector('.gallery')) {
      gsap.to('.gallery', {
        opacity: 1,
        duration: 1,
        ease: 'power1.inOut',
        delay: 0.5,
        stagger: 0.3,
        scale: 1,
      });
    }
  });

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  const lightboxImages = images.map(image => ({
    src: image.attributes.formats.large?.url || image.attributes.url,
    alt: image.attributes.alternativeText || '',
    caption: image.attributes.alternativeText || ''
  }));

  // Ici on place les retours conditionnels après tous les Hooks
  if (loading) return <Loading />;
  if (error) console.log(error);

  const handleSelectionChange = (e) => {
    const selectedCollection = e.target.value;
    navigate(`/gallery/${selectedCollection}`);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  return (
    <div className="h-[calc(100dvh)] flex flex-col overflow-hidden">
      <HelmetProvider>
        <Helmet>
          <title>Julie Demina | Gallery</title>
          <meta name="description" content="Julie Demina's gallery page" />
          <link rel="canonical" href="https://juliedemina.com/gallery" />
          <meta property="og:url" content="https://juliedemina.com/gallery" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Julie Demina | Gallery" />
          <meta property="og:description" content="Julie Demina's gallery page" />
          <meta property="og:image" content="https://julie-demina.s3.eu-west-3.amazonaws.com/Bold_Brand_Name_Initials_Signature_Typography_Logo_6_be5d576e75.png" />
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="flex flex-row justify-center items-baseline w-full gap-2 pt-1 sm:pt-3 md:pt-5 opacity-0 gallery">
        <span className="text-black font-inter text-sm font-medium uppercase text-center transition-all duration-300 ease-in-out">COLLECTION</span>
        
        <div className="relative inline-block w-fit text-left">
          <select
            name="collection"
            id="collection"
            className="block w-full pl-2 pr-8 py-2 text-sm font-bold text-black bg-transparent appearance-none focus:outline-none cursor-pointer"
            value={title}
            onChange={handleSelectionChange}
          >
            {collections.filter(collection => collection.attributes.gallery_images.data.length > 0).map((collection, index) => (
              <option value={collection.attributes.Title} key={index}>
                {collection.attributes.Title}
              </option>
            ))}
          </select>

          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        </div>
      </div>

      <div className="flex-grow flex justify-center items-center opacity-0 gallery">
        <SlideshowLightbox className="fixed w-full flex flex-wrap justify-center gap-3 items-center" lightboxIdentifier="lightbox1" images={lightboxImages} showArrows={false} modalClose="clickOutside" showSlideshowIcon={false} showControls={false}> 
        <Swiper
          modules={[EffectCoverflow, Navigation, Autoplay]}
          effect="coverflow"
          coverflowEffect={{
            rotate: 0,
            stretch: 100,
            depth: 1500,
            modifier: 1,
            slideShadows: false,
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          spaceBetween={200}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView='auto'
          className="w-full flex flex-col justify-center items-center h-fit"
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
        >
          <div className='hidden xl:block'>
            <div className="swiper-button-next hover:scale-110 transition-all duration-300 ease-in-out"></div>
          </div>

          <div className="absolute bottom-0 left-0 right-0 flex xl:hidden justify-between pb-12">
            <div className="swiper-button-prev custom-swiper-button"></div>
            <div className="swiper-button-next custom-swiper-button"></div>
          </div>

            {images.map((image, index) => (
              <SwiperSlide key={image.id} className="flex flex-col justify-center items-center h-full w-full" style={styles.swiperSlide}>
                <div style={{ maxHeight: 'calc(50vh + 100px)', width: 'auto', height: 'auto' }} className='opacity-0 gallery scale-95 px-3 sm:px-0'>
                  <img
                    src={image.attributes.formats.large.url}
                    placeholderSrc={image.attributes.formats.thumbnail.url}
                    alt="Photo"
                    loading="lazy"
                    data-lightboxjs="lightbox1"
                    className="h-auto max-w-full object-cover min-h-full sm:min-h-[50vh] w-auto shadow-xl cursor-pointer"
                    style={{ maxHeight: 'calc(50vh + 100px)', width: 'auto', height: 'auto' }}
                    effect='blur'
                  />
                </div>
                <h2 className="text-center text-black text-sm font-normal font-inter mt-2 sm:mt-4 opacity-0 gallery">{image.attributes.alternativeText}</h2>
              </SwiperSlide>
            ))}

          <div className='hidden xl:block'>
            <div className="swiper-button-prev hover:scale-110 transition-all duration-300 ease-in-out"></div>
          </div>
        </Swiper>
            </SlideshowLightbox> 
      </div>
    </div>
  );
}

export default Collection;
