import { Link, NavLink } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header/Header";
import useFetch from "../../hooks/useFetch";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import gsap from 'gsap';
import Loading from "../../components/UI/loading/loading";
import { Helmet, HelmetProvider } from "react-helmet-async";

function getYouTubeThumbnail(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
  
    if (match && match[2].length === 11) {
      return `https://img.youtube.com/vi/${match[2]}/mqdefault.jpg`;
    }
  
    return null;
  }

export default function Blog() {
    const { loading, error, data } = useFetch('https://julie-demina-portfolio-5cb04ae2d960.herokuapp.com/api/blogs?populate=*')
    const [articles, setArticles] = useState([])
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
    if (data && data.data) {
        setArticles(data.data);
    //   console.log("this is the data", data.data);
    }
    }, [data]);

    useEffect(() => {
      if (!loading && articles.length > 0) {
          // Donnez un court délai après le chargement pour commencer l'animation
          setTimeout(() => {
              setAnimate(true); // Activez l'animation après le chargement
          }, 500); // Ajustez ce délai au besoin
      }
  }, [loading, articles.length]);

  useEffect(() => {
      if (animate) {
          gsap.to(".article", {
              opacity: 1,
              duration: 1,
              stagger: 0.5,
              ease: "power1.inOut",
              x: 0,
              delay: 0.5,
          });
      }
  }, [animate]);

  useEffect(() => {
    if (document.querySelector('.blog')) {
    gsap.to('.ease', {
      opacity: 1,
      duration: 1,
      ease: 'power1.inOut',
      delay: 0.5,
    })
    }
  })
    
      if (loading) return <Loading />;
      if (error) return <p>Erreur : {error}</p>;

    return (
        <div className="flex flex-col min-h-screen blog">
            <HelmetProvider>
            <Helmet>
                <title>Julie Demina | Blog</title>
                <meta name="description" content="Julie Demina's blog page with articles and videos" />
                <link rel="canonical" href="https://juliedemina.com/blog" />
                <meta property="og:url" content="https://juliedemina.com/blog" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Julie Demina | Blog" />
                <meta property="og:description" content="Julie Demina's blog page with articles and videos" />
                <meta property="og:image" content="https://julie-demina.s3.eu-west-3.amazonaws.com/Bold_Brand_Name_Initials_Signature_Typography_Logo_6_be5d576e75.png" />
            </Helmet>
            </HelmetProvider>
        <div className="flex-grow">
            <Header delay={0}/>


            <div className="flex flex-col gap-5 md:gap-10 w-full justify-center items-center mt-5 sm:mt-10">
            {articles.length === 0 && (
            <div className="flex flex-col justify-center items-center opacity-0 ease">
            <h1 className="font-inter text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center">No articles yet</h1>
            <h2 className=" text-sm sm:text-base text-center italic font-bodoni">Please check back later</h2>
            </div>
            )}
            

            {/* <div className="flex flex-col md:flex-row px-5 sm:px-10 w-full lg:w-3/4 xl:w-2/4 justify-center items-center gap-5 md:gap-10 border-t border-black pt-10">
            <img src={require('../../images/homeimage.png')} alt="Photo" className="max-h-[70%] max-w-full md:max-w-[40%] object-cover"/>
            <div className="flex flex-col gap-5">
            <h1 className="font-inter uppercase text-center md:text-left">Title</h1>
            <p className="text-black text-sm font-normal font-inter w-fit">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites...</p>
            </div>
            </div>

            <div className="flex flex-col md:flex-row px-5 sm:px-10 w-full lg:w-3/4 xl:w-2/4 justify-center items-center gap-5 md:gap-10 border-t border-black pt-10">
            <img src={require('../../images/homeimage.png')} alt="Photo" className="max-h-[70%] max-w-full md:max-w-[40%] object-cover"/>
            <div className="flex flex-col gap-5">
            <h1 className="font-inter uppercase text-center md:text-left">Title</h1>
            <p className="text-black text-sm font-normal font-inter w-fit">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites...</p>
            </div>
            </div>

            <div className="flex flex-col md:flex-row px-5 sm:px-10 w-full lg:w-3/4 xl:w-2/4 justify-center items-center gap-5 md:gap-10 border-t border-black pt-10">
            <img src={require('../../images/homeimage.png')} alt="Photo" className="max-h-[70%] max-w-full md:max-w-[40%] object-cover"/>
            <div className="flex flex-col gap-5">
            <h1 className="font-inter uppercase text-center md:text-left">Title</h1>
            <p className="text-black text-sm font-normal font-inter w-fit">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites...</p>
            </div>
            </div> */}

            {articles.map((article, index) => (
                <Link className="flex flex-col md:flex-row px-5 sm:px-10 w-full lg:w-3/4 xl:w-2/4 justify-center items-center gap-5 md:gap-10 pt-10 opacity-0 article" key={article.id} to={`/blog/${article.id}`}>
                {article.attributes.Image.data && article.attributes.Image.data.length > 0 && (
                <img 
                src={`${article.attributes.Image.data[0].attributes.url}`} 
                alt={article.attributes.Image.data[0].attributes.alternativeText}  
                className="max-h-[70%] max-w-full md:max-w-[40%] object-cover rounded-md"
                loading="lazy"
                />
                )}
                {article.attributes.YoutubeURL && (
                    <img 
                    src={getYouTubeThumbnail(article.attributes.YoutubeURL)}
                    alt="YouTube video"
                    className="max-h-[70%] max-w-full md:max-w-[40%] object-cover"
                    loading="lazy"
                    />
                )}
                <div className="flex flex-col gap-2">
                <Markdown className="font-inter font-bold text-center md:text-left text-black">{article.attributes.Title}</Markdown>
                <p className="text-black text-sm font-normal font-inter w-fit line-clamp-3">{article.attributes.Texte}</p>
                </div>
                </Link>
            ))}

            </div>
            </div>

            <Footer />
            
        </div>
    )
}